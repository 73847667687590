<template>
  <v-dialog v-model="visible" persistent max-width="600">
    <v-card class="pa-5 rounded-lg" id="card-custom">
      <v-btn @click="visible = false" x-small icon class="float-right">
        <v-icon>close</v-icon>
      </v-btn>
      <v-card-text class="subtitle-2 px-0 font-weight-regular">
        {{ form.action == "add" ? "Tambah" : "Ubah" }} Data Keluarga
      </v-card-text>
      <v-divider class="mb-4" />
      <v-form
        id="form"
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="save()"
      >
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >NIP</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-text-field
              v-model="form.NIP"
              outlined
              dense
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >Hubungan</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-select
              v-if="form.action == 'add'"
              v-model="form.hubungan"
              :items="hubunganList"
              outlined
              dense
              :rules="[v => !!v || 'Hubungan harus diisi']"
              item-text="text"
              item-value="value"
              disabled
            ></v-select>
            <v-text-field
              v-else
              :value="`${form.hubungan}`"
              outlined
              dense
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >Jenis Kelamin</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-select
              v-model="form.kelamin"
              :items="kelaminList"
              :disabled="disabled || form.hubungan == 'pasangan'"
              outlined
              dense
              :rules="[v => !!v || 'Jenis Kelamin harus diisi']"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >Nama</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-text-field
              v-model="form.nama"
              outlined
              dense
              :rules="[v => !!v || 'Nama harus diisi']"
              :disabled="disabled"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >Tempat Lahir</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-card-actions class="pa-0">
              <v-text-field
                v-model="form.tempat_lahir"
                outlined
                dense
                :rules="[v => !!v || 'Tempat lahir harus diisi']"
                :disabled="disabled"
              ></v-text-field>
              <v-text-field
                v-model="form.tanggal_lahir"
                class="ml-1"
                type="date"
                outlined
                dense
                :rules="[v => !!v || 'Tanggal lahir harus diisi']"
                :disabled="disabled"
              ></v-text-field>
            </v-card-actions>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >Tanggal Nikah</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-text-field
              v-model="form.tanggal_nikah"
              type="date"
              outlined
              dense
              :disabled="disabled"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >Pekerjaan</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-text-field
              v-model="form.pekerjaan"
              outlined
              dense
              :rules="[v => !!v || 'Pekerjaan harus diisi']"
              :disabled="disabled"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >Keterangan</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-text-field
              v-model="form.keterangan"
              outlined
              dense
              :rules="[v => !!v || 'Keterangan harus diisi']"
              :disabled="disabled"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions class="mt-3">
          <v-spacer />
          <v-btn
            small
            outlined
            color="#717171"
            class="px-12"
            @click="visible = false"
            >Batal</v-btn
          >
          <slot name="action">
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Tambahkan</v-btn
            >
          </slot>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import PegawaiService from "@/services/resources/pegawai.service";

export default {
  props: {
    disabled: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      visible: false,
      valid: false,
      formLoading: false,
      kelaminList: ["Pria", "Wanita"],
      hubunganList: [
        {
          value: "pasangan",
          text: "Pasangan"
        },
        {
          value: "anak",
          text: "Anak"
        },
        {
          value: "orang_tua",
          text: "Orang Tua"
        },
        {
          value: "mertua",
          text: "Mertua"
        },
        {
          value: "saudara",
          text: "Saudara"
        }
      ],
      length: 1,
      form: {
        action: "add",
        NIP: null,
        hubungan: null,
        kelamin: null,
        nama: null,
        tempat_lahir: null,
        tanggal_lahir: null,
        tanggal_nikah: null,
        pekerjaan: null,
        keterangan: null
      }
    };
  },
  watch: {
    visible(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    }
  },
  methods: {
    dismiss() {
      this.visible = false;
    },
    add(item, length) {
      this.length = length;
      this.form = this.$_.merge(this.form, item);
      this.form.action = "add";
      this.visible = true;
    },
    edit(item, length) {
      this.length = length;
      this.form = this.$_.merge(this.form, item);
      this.form.action = "edit";
      this.visible = true;
    },
    addNumber(number) {
      number = String(number);
      if (number.length == 1) {
        return `0${number}`;
      }
      return `${number}`;
    },
    save() {
      if (this.$refs.form.validate()) {
        let hubungan = this.form.hubungan;
        if (this.form.action == "add") {
          if (this.form.hubungan == "pasangan") {
            if (this.form.kelamin == "Pria") {
              hubungan = `Suami${this.addNumber(this.length + 1)}`;
            } else {
              hubungan = `Istri${this.addNumber(this.length + 1)}`;
            }
          } else if (this.form.hubungan == "anak") {
            hubungan = `Anak${this.addNumber(this.length + 1)}`;
          } else if (this.form.hubungan == "orang_tua") {
            if (this.form.kelamin == "Pria") {
              hubungan = `BapakKandung${this.addNumber(this.length + 1)}`;
            } else {
              hubungan = `IbuKandung${this.addNumber(this.length + 1)}`;
            }
          } else if (this.form.hubungan == "mertua") {
            if (this.form.kelamin == "Pria") {
              hubungan = `BapakMertua${this.addNumber(this.length + 1)}`;
            } else {
              hubungan = `IbuMertua${this.addNumber(this.length + 1)}`;
            }
          } else if (this.form.hubungan == "saudara") {
            hubungan = `SaudaraKandung${this.addNumber(this.length + 1)}`;
          }
        }

        let formData = new FormData();
        formData.append("action", this.form.action == "add" ? 1 : 2);
        formData.append("NIP", this.form.NIP);
        formData.append("hubungan", hubungan);
        formData.append("kelamin", this.form.kelamin == "Pria" ? 1 : 2);
        formData.append("nama", this.form.nama);
        formData.append("tempat_lahir", this.form.tempat_lahir);
        formData.append("tgl_lahir", this.form.tanggal_lahir);
        formData.append("tgl_nikah", this.form.tanggal_nikah);
        formData.append("pekerjaan", this.form.pekerjaan);
        formData.append("keterangan", this.form.keterangan);

        console.log(hubungan);
        this.saveFamily(formData);
      }
    },
    // Service
    async saveFamily(payload) {
      try {
        this.formLoading = true;
        await PegawaiService.saveFamily(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.visible = false;
              this.$emit("on-refresh");
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
